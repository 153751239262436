<template>
    <div>
        <!-- 地区选择 -->
        <el-cascader
                v-if="isShow"
                ref="cascaderAddr"
                style="width: 100%"
                v-model="val"
                :props="datalist"
                placeholder="请选择省/市/区（县）"
                clearable
                @change="handleChange">
        </el-cascader>
    </div>
</template>

<script>
// let id = 0;
export default {
    name: 'Region',
    // 父组件参数
    props: {
        value: null,
        onlyallow: {
            type: null,
            default: '0'    // 仅获取允许的城市
        }
    },
    data() {
        let thi = this
        return {
            isShow: true,
            // val: [120000, 120100, 120103],
            val: [],
            datalist: {
                value: 'region_code',
                label: 'region_name',
                lazy: true,
                lazyLoad(node, resolve) {
                    if (node.level == 0) {
                        thi.getregion(1, node.value, resolve, false)       // 获取省
                    } else if (node.level == 1) {
                        thi.getregion(2, node.value, resolve, false)       // 获取市
                    } else if (node.level == 2) {
                        thi.getregion(3, node.value, resolve, true)        // 获取区县
                    } else {
                        console.log('当前点击x', node)
                    }
                }
            }
        }
    },
    // 创建
    created() {

    },
    // 安装
    mounted() {
        this.init(this.value)
    },
    methods: {
        // 初始化
        init(region_code) {
            if (region_code != '') {
                // console.log('region_code', region_code)
                region_code = String(region_code)
                let province_code = region_code.substring(0, 2) + '0000';
                let city_code = region_code.substring(0, 4) + '00';
                this.val = [Number(province_code), Number(city_code), Number(region_code)]
                // console.log('选中的值', this.val);
                // 刷新组件
                this.isShow = false;
                setTimeout(() => {
                    this.isShow = true;
                }, 100);
            }

        },
        // 获取地址
        getregion(region_level, region_code, resolve, leaf = false) {
            let postdata = {
                api_name: 'home.region.getlist',
                token: this.Tool.get_l_cache('token'),
                region_level: region_level,
                region_code: region_code,
                onlyallow: this.onlyallow,
            }
            this.Tool.post_data('oss', postdata, (json) => {
                if (json.code === 0) {
                    if (leaf) {
                        for (let x of json.data) {
                            x.leaf = true
                        }
                        resolve(json.data);
                    } else {
                        resolve(json.data);
                    }
                } else {
                    this.$message.error(json.message);
                }
            })
        },
        // 选择
        handleChange(value) {
            // console.log('value', value)
            if (value.length > 0) {
                let Label = this.$refs["cascaderAddr"].getCheckedNodes()[0].pathLabels;
                let region_date = {
                    province: {region_code: value[0], region_level: Label[0]},
                    city: {region_code: value[1], region_level: Label[1]},
                    county: {region_code: value[2], region_level: Label[2]},
                }
                this.$emit('input', value[2])
                this.$emit('region', region_date)
            } else {
                this.$emit('input', '')
                this.$emit('region', [])
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
