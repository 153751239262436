<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/company/company' }">列表</el-breadcrumb-item>
                <el-breadcrumb-item>详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--标题-->
            <el-row>
                <el-col :span="12">
                    <h3>{{ page_name }}</h3>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--详情-->
            <el-row>
                <el-col :span="16">
                    <el-form v-loading="loading" size="medium" ref="form" :model="form" :label-width="this.env.label_width">
                        <el-form-item label="名称">
                            <el-input v-model="form.company_name"></el-input>
                        </el-form-item>
                        <el-form-item label="Logo">
                            <Qnupload v-model="form.logo" :sum="1" :readonly='true' />
                        </el-form-item>
                        <el-form-item label="类型" prop="sex">
                            <el-radio-group v-model="form.type">
                                <el-radio :label="1">商户</el-radio>
                                <el-radio :label="2">个人</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="地址选择">
                            <Region ref="region" v-model="form.region_code" v-on:region="getregion" />
                        </el-form-item>
                        <el-form-item label="地址">
                            <el-input v-model="form.address"></el-input>
                        </el-form-item>
                        <el-form-item label="联系人姓名">
                            <el-input v-model="form.company_contacts"></el-input>
                        </el-form-item>
                        <el-form-item label="联系人电话号码">
                            <el-input v-model="form.company_contacts_mobile"></el-input>
                        </el-form-item>
                        <el-form-item label="门店照片">

                            <div v-if="company_uuid !== ''">
                                <Qnupload v-model="form.photo" :sum="5" :readonly='true' />
                            </div>
                            <div v-else>
                                <Qnupload v-model="form.photo" :sum="5" :isclipper="true" :fixedNumber="[4, 3]" />
                                <span class="form-tip">图片尺寸1024*768 或 宽:高≈4:3</span>
                            </div>
                        </el-form-item>
                        <el-form-item label="统一社会信用代码">
                            <el-input v-model="form.uniform_social_credit_code"></el-input>
                        </el-form-item>
                        <el-form-item label="营业执照">
                            <div v-if="company_uuid !== ''">
                                <Qnupload v-model="form.business_license" :sum="3" :readonly='true' />
                            </div>
                            <div v-else>
                                <Qnupload v-model="form.business_license" :sum="3" />
                            </div>
                        </el-form-item>
                        <el-form-item label="银行卡号">
                            <el-input v-model="form.bank_card_no" :maxlength="100"></el-input>
                        </el-form-item>
                        <el-form-item label="银行户名">
                            <el-input v-model="form.bank_card_name" :maxlength="100"></el-input>
                        </el-form-item>
                        <el-form-item label="开户行">
                            <el-input v-model="form.bank_of_deposit" :maxlength="100"></el-input>
                        </el-form-item>
                        <!--                        <el-form-item label="租房合同">-->
                        <!--                            <Qnupload v-model="form.house_contract_img" :sum="3" :readonly='true'/>-->
                        <!--                        </el-form-item>-->
                        <el-form-item label="简介">
                            <!-- 富文本编辑框 -->
                            <Wangeditor ref="wangeditor" v-model="form.content" idindex="1" seewWidth="50%" />
                        </el-form-item>
                        <el-form-item v-if="!is_empty(this.company_uuid)" label="短信通知">
                            <el-switch v-model="form.issms" :active-value="1" :inactive-value="0"
                                @change="smsset"></el-switch>
                        </el-form-item>
                        <el-form-item>
                            <el-button v-if="is_auth('company.company.issave') && this.company_uuid === ''" size="medium"
                                type="primary" @click="save">保存
                            </el-button>
                            <el-button v-if="is_auth('company.company.examinenew') && this.form.status === 0"
                                size="medium" @click="examine(1)" type="success">审核通过
                            </el-button>
                            <el-button v-if="is_auth('company.company.examinenew') && this.form.status === 0"
                                size="medium" @click="examine(2)" type="danger">审核未通过
                            </el-button>
                            <el-button size="medium" @click="isreturn">返回</el-button>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
import Wangeditor from '@/components/Wangeditor'
import Qnupload from '@/components/Qnupload'
import Region from '@/components/Region'

export default {
    components: {
        Wangeditor,
        Qnupload,
        Region,
    },
    data() {
        return {
            page_name: '商户详情',
            loading: true,
            company_uuid: '',
            form: {
                company_name: '',
                company_contacts: '',
                company_contacts_mobile: '',
                province: '',
                city: '',
                county: '',
                address: '',
                type: 1,
                uniform_social_credit_code: '',
                business_license: [],
                content: '',
                region_code: '',
                issms: 0,


                bank_card_no: '',
                bank_card_name: '',
                bank_of_deposit: '',
                house_contract_img: [],
                photo: [],
            },
        }
    },
    // 创建
    created() {
        this.loading = false
        this.init()
    },
    // 安装
    mounted() {
    },
    methods: {
        // 判断是否是空
        is_empty(data) {
            return this.Tool.is_empty(data)
        },
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            let company_uuid = this.$route.query.company_uuid
            if (company_uuid !== undefined) {
                this.company_uuid = company_uuid
                this.getinfo();
            } else {
                this.loading = false
            }
        },
        // 商户详情
        getinfo() {
            let postdata = {
                api_name: "company.company.getinfo",
                token: this.Tool.get_l_cache('token'),
                company_uuid: this.company_uuid
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.form = json.data
                    this.$refs.wangeditor.append(json.data.content);
                    this.$refs.region.init(json.data.region_code);
                } else {
                    this.$message.error(json.message);
                }
            })
        },
        //设置短信提醒
        smsset(val) {
            let postdata = {
                api_name: "company.company.smsset",
                token: this.Tool.get_l_cache('token'),
                company_uuid: this.company_uuid,
                issms: val,
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.form.issms = val
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                    });
                } else {
                    this.$message.error(json.message);
                }
            })
        },
        // 地址
        getregion(region_date) {
            this.form.province = region_date.province.region_level
            this.form.city = region_date.city.region_level
            this.form.county = region_date.county.region_level
        },
        //审核通过
        examine(examine_state) {
            let postdata = {
                api_name: "company.company.examinenew",
                token: this.Tool.get_l_cache('token'),
                company_uuid: this.company_uuid,
                examine_state: examine_state,
            }

            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getinfo() // 获取数据
                        }
                    });
                } else {
                    this.$message.error(json.message);
                }
            })

        },

        //保存
        save() {
            let postdata = {
                api_name: "company.company.issave",
                token: this.Tool.get_l_cache('token'),
                company_name: this.form.company_name,
                company_contacts: this.form.company_contacts,
                company_contacts_mobile: this.form.company_contacts_mobile,
                province: this.form.province,
                city: this.form.city,
                county: this.form.county,
                region_code: this.form.region_code,
                address: this.form.address,
                type: this.form.type,
                uniform_social_credit_code: this.form.uniform_social_credit_code,
                business_license: this.form.business_license,
                content: this.form.content,

                bank_card_no: this.form.bank_card_no,
                bank_card_name: this.form.bank_card_name,
                bank_of_deposit: this.form.bank_of_deposit,
                house_contract_img: this.form.house_contract_img,
                photo: this.form.photo,

            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.$router.push({ path: '/company/company' })    // 返回列表
                        }
                    });
                } else {
                    this.$message.error(json.message);
                }
            })

        },
        // 返回
        isreturn() {
            // this.$router.push({path: '/company/company'})
            this.$router.go(-1)
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
